class Color(private val color: Int) {

    override fun toString(): String {
        return "#" + color.toString(16).padStart(6, '0')
    }

    companion object {
        val I = Color(0x00E5FF)
        val O = Color(0xFFEA00)
        val T = Color(0xD500F9)
        val S = Color(0x00E676)
        val Z = Color(0xFF1744)
        val J = Color(0x2979FF)
        val L = Color(0xFF9100)

        val default = Color(0x212121)
    }

    override fun equals(other: Any?): Boolean {
        return if (other !is Color) {
            false
        } else {
            color == other.color
        }
    }

    override fun hashCode(): Int {
        return color
    }
}