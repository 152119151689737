import org.w3c.dom.CanvasRenderingContext2D

class Board(width: Int, height: Int) {
    val content = Array(height) {
        Array(width) {
            @Suppress("USELESS_CAST")
            null as Color?
        }
    }
    var pieces = arrayListOf<Pair<Pair<Int, Int>, Matrix<Color>>>()

    fun draw(context2D: CanvasRenderingContext2D) {
        val cellSize = context2D.canvas.height / content.size

        for ((i, row) in content.withIndex()) {
            for ((j, cell) in row.withIndex()) {
                if (cell != null) {
                    context2D.fillStyle = cell.toString()
                    context2D.fillRect(cellSize * j, cellSize * i, cellSize, cellSize)
                }
            }
        }

        for((topLeft, matrix) in pieces){
            for(i in 0 until matrix.size){
                for(j in 0 until matrix.size){
                    if(matrix[i,j]!=Color.default){
                        context2D.fillStyle = matrix[i,j].toString()
                        context2D.fillRect(cellSize * (j+topLeft.second), cellSize * (i+topLeft.first), cellSize, cellSize)
                    }
                }
            }
        }
    }

    fun hasCollision(): Boolean {
        for (piece in pieces) {
            for (originalI in 0 until piece.second.size) {
                val i = originalI + piece.first.first
                for (originalJ in 0 until piece.second.size) {
                    val j = originalJ + piece.first.second
                    if (i >= content.size || i < 0) {
                        if (piece.second[originalI, originalJ] == Color.default) {
                            continue
                        }
                        else {
                            return true
                        }
                    }
                    if (j >= content[0].size || j < 0) {
                        if (piece.second[originalI, originalJ] == Color.default) {
                            continue
                        }
                        else {
                            return true
                        }
                    }
                    if (piece.second[originalI, originalJ] != Color.default && content[i][j] != Color.default && content[i][j] != null) {
                        return true
                    }
                }
            }
        }
        return false
    }

    fun place() {
        for (piece in pieces) {
            for (originalI in 0 until piece.second.size) {
                val i = originalI + piece.first.first
                for (originalJ in 0 until piece.second.size) {
                    val j = originalJ + piece.first.second
                    if (piece.second[originalI, originalJ] != Color.default) {
                        content[i][j] = piece.second[originalI, originalJ]
                    }
                }
            }
        }
    }

    fun clearFullLines(): Int {
        var linesCleared = 0
        deleteLoop@ for (i in 0 until 20) {
            for (color in content[i]) {
                if (color == null || color == Color.default) {
                    continue@deleteLoop
                }
            }

            for (deleteRow in i downTo 1) {
                for (j in content[i].indices) {
                    content[deleteRow][j] = content[deleteRow - 1][j]
                }
            }
            content[0] = Array(content[0].size) { null }
            linesCleared += 1
        }
        return linesCleared
    }
}

fun CanvasRenderingContext2D.fillRect(x: Int, y: Int, w: Int, h: Int) =
    this.fillRect(x.toDouble(), y.toDouble(), w.toDouble(), h.toDouble())