import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent
import kotlin.js.Date

var frameCounter: Long = 0

var board = Board(10, 20)
var queue = Board(6, 10)
var hold = Board(6, 6)

var currentPiece: Matrix<Color> = Matrix.O
var currentPiecePosition = -1 to 0
var timeUntilDrop = 0

var gameLoopId: Int? = null

var score = 0

var canHold = true

val pieceOptions = arrayListOf(
    (0 to 3) to Matrix.I,
    (0 to 3) to Matrix.L,
    (0 to 3) to Matrix.J,
    (0 to 3) to Matrix.Z,
    (0 to 3) to Matrix.S,
    (0 to 4) to Matrix.O,
    (0 to 3) to Matrix.T,
)

fun willCollide(futurePieceLocation: Pair<Int, Int>): Boolean {
    val oldPiece = board.pieces[0]
    board.pieces[0] = futurePieceLocation to currentPiece

    val result = board.hasCollision()

    board.pieces[0] = oldPiece

    return result
}

fun main() {
    document.addEventListener("keydown", EventListener {
        val SHIFT_CODE = 16

        val event = it as KeyboardEvent

        if (event.keyCode == SHIFT_CODE) {
            if (canHold) {
                val nextPiece = when {
                    hold.pieces.isNotEmpty() -> {
                        hold.pieces.removeAt(0)
                    }
                    queue.pieces.isNotEmpty() -> {
                        queue.pieces.removeAt(0)
                    }
                    else -> {
                        return@EventListener
                    }
                }

                hold.pieces.add((1 to 1) to currentPiece)
                currentPiece = nextPiece.second
                currentPiecePosition = 0 to 3
                canHold = false
            }
        }
    })
    document.addEventListener("keypress", EventListener {
        val SPACE_CODE = 32
        val LEFT_CODE = "a"
        val RIGHT_CODE = "d"
        val DOWN_CODE = "s"
        val UP_CODE = "w"
        val event = it as KeyboardEvent
        if (event.keyCode == SPACE_CODE) {
            restart()
        }
        else if (event.key == LEFT_CODE) {
            val futurePieceLocation = currentPiecePosition.first to currentPiecePosition.second - 1
            if (!willCollide(futurePieceLocation)) {
                currentPiecePosition = futurePieceLocation
            }
        }
        else if (event.key == RIGHT_CODE) {
            val futurePieceLocation = currentPiecePosition.first to currentPiecePosition.second + 1
            if (!willCollide(futurePieceLocation)) {
                currentPiecePosition = futurePieceLocation
            }
        }
        else if (event.key == DOWN_CODE) {
            if (timeUntilDrop > 1) {
                timeUntilDrop = 1
            }
        }
        else if (event.key == UP_CODE) {
            val oldPiece = currentPiece
            currentPiece = currentPiece.clone().apply { rotate() }
            if (willCollide(currentPiecePosition)) {
                currentPiece = oldPiece
            }
        }
        else if (event.key == "p") {
            togglePause()
        }
    })
    window.addEventListener("blur", EventListener {
        togglePause()
    })
    window.addEventListener("focus", EventListener {
        togglePause()
    })
}

fun togglePause() {
    if (gameLoopId == null) {
        statusText = null
        gameLoopId = window.setInterval(::gameLoop, 16)
    }
    else {
        window.clearInterval(gameLoopId!!)
        gameLoopId = null
        statusText = "PAUSED"
    }
}

fun restart() {
    stop()

    board = Board(10, 20)
    queue = Board(6, 10)
    hold = Board(6, 6)

    currentPiece = Matrix.O
    currentPiecePosition = -1 to 0

    frameCounter = 0

    gameLoopId = window.setInterval(::gameLoop, 16)

    score = 0

    canHold = true

    statusText = null
}

fun stop() {
    gameLoopId?.let { window.clearInterval(it) }
    gameLoopId = null
}

fun gameLoop() {
//    val startTime = Date.now()

    val height = window.innerHeight * 9 / 10
    val width = window.innerWidth

    val gameObj = document.getElementById("game") as HTMLCanvasElement
    val holdObj = document.getElementById("hold") as HTMLCanvasElement
    val nextObj = document.getElementById("next") as HTMLCanvasElement
    val scoreObj = document.getElementById("score") as HTMLPreElement

    queueWhile@ while (queue.pieces.size < 3) {
        val newPiece = pieceOptions.random()
        for (piece in queue.pieces) {
            if (piece.second == newPiece.second) {
                continue
            }
        }
        queue.pieces.add( pieceOptions.random())
    }

    run {
        val xEs = listOf(1, 4, 7)
        for (i in 0 until 3) {
            queue.pieces[i] = (xEs[i] to queue.pieces[i].first.second) to queue.pieces[i].second
        }
    }

//    scoreObj.textContent = "$frameCounter".padStart(6, ' ')
    gameObj.withContext {
        clearRect(0.0, 0.0, gameObj.width.toDouble(), gameObj.height.toDouble())

        if (frameCounter > 0L) {
            board.pieces.clear()
            board.pieces.add(currentPiecePosition to currentPiece)
        }

        draw(board)
    }

//    board.pieces = arrayListOf(
//        (0 to 3) to Matrix.I,
//        (2 to 3) to Matrix.L,
//        (4 to 3) to Matrix.J,
//        (6 to 3) to Matrix.Z,
//        (8 to 3) to Matrix.S,
//        (10 to 3) to Matrix.O,
//        (12 to 3) to Matrix.T
//    )

    nextObj.withContext {
        clearRect(0.0, 0.0, nextObj.width.toDouble(), nextObj.height.toDouble())
        draw(queue)
    }

    holdObj.withContext {
        clearRect(0.0, 0.0, holdObj.width.toDouble(), holdObj.height.toDouble())
        draw(hold)
    }

    val STEP_TIMER = 30

    fun newPiece() {
        currentPiece = queue.pieces.removeAt(0).second
        currentPiecePosition = 0 to 3
        timeUntilDrop = STEP_TIMER
    }

    if (frameCounter == 0L) {
        newPiece()
    }
    else {
        // Handle piece moving somewhere
        timeUntilDrop -= 1
        if (timeUntilDrop == 0) {
            val newPosition = currentPiecePosition.first + 1 to currentPiecePosition.second
            if (willCollide(newPosition)) {
                board.place()
                val removedLines = board.clearFullLines()
                score += if (removedLines == 4) { 800 } else { removedLines * 100 }
                newPiece()
                if (willCollide(currentPiecePosition)) {
                    // Game over, new piece collides
                    statusText = "GAME OVER"
                    stop()
                }
                canHold = true
            }
            else {
                currentPiecePosition = newPosition
            }
            timeUntilDrop = STEP_TIMER
        }

    }

    frameCounter++

//    val endTime = Date.now()
//
//    val deltaTime = endTime - startTime
//    val fps = 1000.0 / deltaTime
//    scoreText = fps.toString()
    scoreText = score.toString().padStart(6)
}

var statusText: String?
    get() = document.getElementById("status")!!.textContent
    set(value) {
        document.getElementById("status")!!.textContent = value
    }

var scoreText: String?
    get() = document.getElementById("score")!!.textContent
    set(value) {
        document.getElementById("score")!!.textContent = value
    }

fun HTMLCanvasElement.withContext(callback: CanvasRenderingContext2D.() -> Unit) {
    (this.getContext("2d") as CanvasRenderingContext2D).callback()
}

infix fun CanvasRenderingContext2D.draw(board: Board) {
    board.draw(this)
}