class Matrix<T>(val size: Int, private val default: T) {
    private var matrix = ArrayList<ArrayList<T>>(size)

    init {
        for (i in 0 until size) {
            matrix.add(ArrayList(size))
            for (j in 0 until size) {
                matrix[i].add(default)
            }
        }
    }

    constructor(matrix: ArrayList<ArrayList<T>>, default: T) : this(matrix.size, default) {
        for (i in 0 until size) {
            for (j in 0 until size) {
                this.matrix[i][j] = matrix[i][j]
            }
        }
    }

    fun clone(): Matrix<T> = Matrix(matrix, default)

    operator fun get(i: Int, j: Int): T {
        return matrix[i][j]
    }

    operator fun set(i: Int, j: Int, value: T) {
        matrix[i][j] = value
    }

    fun rotate() {
        val newMatrix = ArrayList<ArrayList<T>>(size)
        for (i in 0 until size) {
            newMatrix.add(ArrayList(size))
            for (j in 0 until size) {
                newMatrix[i].add(matrix[size - j - 1][i])
            }
        }
        matrix = newMatrix
    }

    override fun equals(other: Any?): Boolean {
        if (other !is Matrix<*>) {
            return false
        }
        return matrix == other.matrix
    }

    companion object {
        val I = Matrix(4, Color.default).apply {
            this[1, 0] = Color.I
            this[1, 1] = Color.I
            this[1, 2] = Color.I
            this[1, 3] = Color.I
        }
        val J = Matrix(3, Color.default).apply {
            this[0, 0] = Color.J
            this[1, 0] = Color.J
            this[1, 1] = Color.J
            this[1, 2] = Color.J
        }
        val L = Matrix(3, Color.default).apply {
            this[0, 2] = Color.L
            this[1, 0] = Color.L
            this[1, 1] = Color.L
            this[1, 2] = Color.L
        }
        val O = Matrix(2, Color.default).apply {
            this[0, 0] = Color.O
            this[0, 1] = Color.O
            this[1, 0] = Color.O
            this[1, 1] = Color.O
        }
        val S = Matrix(3, Color.default).apply {
            this[0, 1] = Color.S
            this[0, 2] = Color.S
            this[1, 0] = Color.S
            this[1, 1] = Color.S
        }
        val T = Matrix(3, Color.default).apply {
            this[0, 1] = Color.T
            this[1, 0] = Color.T
            this[1, 1] = Color.T
            this[1, 2] = Color.T
        }
        val Z = Matrix(3, Color.default).apply {
            this[0, 0] = Color.Z
            this[0, 1] = Color.Z
            this[1, 1] = Color.Z
            this[1, 2] = Color.Z
        }
    }
}